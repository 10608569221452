import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ComponentName } from 'src/enums/componentName.enum';
import { PasswordChangemodalComponent } from 'src/modals/password-changemodal/password-changemodal.component';
import { TokenResolverService } from 'src/providers/token-resolver.service';
import { ResetPasswordComponent } from 'src/reset-password/reset-password.component';

const routes: Routes = [{ path: '', redirectTo: ComponentName.LOGIN, pathMatch: 'full' }, { path: ComponentName.LOGIN, loadChildren: './login/login.module#LoginModule' },
{ path: 'Change-password/:token', component: PasswordChangemodalComponent },
{ path: ComponentName.ADMIN, loadChildren: './login/login.module#LoginModule' },
{ path: 'reset-password/:token', component: ResetPasswordComponent },

{
  path: ComponentName.MENU,
  loadChildren: './home/home.module#HomeModule',
  resolve: {
    access: TokenResolverService
  },
},
{ path: ComponentName.CALLBACK, redirectTo: ComponentName.MENU },
{ path: '**', redirectTo: 'page-not-found' }];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  declarations: [],
  exports: [RouterModule]
}) export class AppRouteModule { }
