import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog,MatDialogRef, MatSnackBar } from '@angular/material';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/providers/admin.service';
import { UsersService } from 'src/providers/users.service';
function passwordMatchValidator(password: string): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    }
    return control.parent.get(password).value === control.value ? null : { mismatch: true };
  };
}
@Component({
  selector: 'app-password-changemodal',
  templateUrl: './password-changemodal.component.html',
  styleUrls: ['./password-changemodal.component.css']
})
export class PasswordChangemodalComponent implements OnInit {
  forGotPasswordForm: FormGroup;
  hideNewPassword:boolean = true;
  hideConfirmPassword:boolean = true;
  constructor(private formBuilder: FormBuilder, private adminService: AdminService, private matSnackBar: MatSnackBar, private userService: UsersService, private activatedRoute: ActivatedRoute, private router: Router) { }
  ngOnInit() {
    this.forGotPasswordForm = this.formBuilder.group({
      old_password: ['', [
        Validators.required,
        Validators.minLength(6),
        this.noSpacesValidator()
      ]],
      new_password: ['', [
        Validators.required,
        Validators.minLength(6),
        this.noSpacesValidator()
      ]],
      confirm_password: ['', [
        Validators.required,
        this.noSpacesValidator(),
        passwordMatchValidator('new_password'),
      
      ]],
      // user_login_id: [this.userService.getUserId()],
      // org_id: [this.userService.getOrgId()],

      // token: [this.activatedRoute.snapshot.params['token'], [
      //   Validators.required
      // ]]

      user_login_id: [parseInt(this.activatedRoute.snapshot.queryParams['user_login_id'])],
      org_id: [parseInt(this.activatedRoute.snapshot.queryParams['org_id'])],
      token: [this.activatedRoute.snapshot.params['token']],
      
    });
  }
  checkPasswords(group: FormGroup) { 
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmedPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  resetPassword() {
    if (this.forGotPasswordForm.valid) {
      // let userLoginId = this.userService.getUserId();
      // console.log(userLoginId);
      this.adminService.changePassword(this.forGotPasswordForm.value).subscribe(res => {
        if (res.status) {
          this.matSnackBar.open(res.message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          });
          window.location.href = environment.devServerUrl;
        } else {
          this.matSnackBar.open(res.message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
      });
    } else if(this.forGotPasswordForm.controls.confirm_password.errors){
      if(this.forGotPasswordForm.controls.confirm_password.errors.mismatch){
      this.matSnackBar.open('New password and confirm password must be same', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
    else if(this.forGotPasswordForm.controls.confirm_password.errors.Spaces){
      this.matSnackBar.open('Spaces are not allowed in the passwords', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
    }
    else{
      this.matSnackBar.open('Please fill all required fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
  noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string; // Ensure value is a string
      console.log('hooooooooooooo',typeof value)
      if ( value!=null && typeof value === 'string' && !value.startsWith(' ') && !value.includes(' ')) {
        return null; // Validation passed
      }
      return { 'Spaces': true }; // Validation failed
  }
}
}
