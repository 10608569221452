import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Resolve } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { sessionStorageKeys } from 'src/enums/session-storage-keys.enum';import { AwsCognitoService } from './aws-cognito.service';
import { LoginService } from './login.service';
import { UsersService } from './users.service';@Injectable()
export class TokenResolverService implements Resolve<any> {  constructor(private location: Location,
              private awsCognitoService: AwsCognitoService, public ac: ActivatedRoute, private http: HttpClient, private loginService: LoginService, private userService: UsersService) { }  resolve(): Observable<any | null> {    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: string = urlParams.get('code');    if (!code) {
      return of(null);
    }    return this.getTokenDetailsFromCognito(code).pipe(
      finalize(() => {
        this.location.replaceState(window.location.pathname);
      })
    );
  }  getTokenDetailsFromCognito(code: string): Observable<any | null> {
    return this.awsCognitoService.getTokenDetailsFromCognito(code).pipe(
      switchMap((response: any) => {
        const decoded: any = jwt_decode(response.id_token);
        this.userService.setSessionstorage(sessionStorageKeys.ingen_token, response.id_token);        return this.loginService.getUserDetails().toPromise().then(res => {
          this.userService.setSessionstorage(sessionStorageKeys.isLogin, 'sso');
          this.userService.setSessionstorage(sessionStorageKeys.ingen_userId, res.data.fetchUserDetails.userLoginId);
          this.userService.setSessionstorage(sessionStorageKeys.OrgDetails, JSON.stringify(res.data.fetchOrgDetails));
          this.userService.setSessionstorage(sessionStorageKeys.UserDetails, JSON.stringify(res.data.fetchUserDetails));
          return of(response);
        });
      }),
      catchError ((error) => {
        return error;
      })
    );
  }
}
