import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule } from 'ngx-loading';
import { ClientDataModule } from 'src/modules/client-data/client-data.module';
import { FeatureModule } from 'src/modules/feature/feature.module';
import { LoginModule } from 'src/modules/login/login.module';
import { AuthInterceptor } from 'src/providers/auth.interceptor';
import { TokenResolverService } from 'src/providers/token-resolver.service';import { AppRouteModule } from '../modules/app-route.module';
import { LoginService } from '../providers/login.service';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { UserIdleModule } from 'angular-user-idle';
import { SessionExpireAlertComponent } from 'src/session-expire-alert/session-expire-alert.component';
import { MaterialModule } from 'src/modules/material.module';
import { ResetPasswordComponent } from 'src/reset-password/reset-password.component';
import { PasswordChangemodalComponent } from 'src/modals/password-changemodal/password-changemodal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'src/modules/table/table.module';
// import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
export let AppInjector: Injector;
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    SessionExpireAlertComponent,
    ResetPasswordComponent,
    PasswordChangemodalComponent
  ],
  imports: [
     HttpClientModule ,
    NgxLoadingModule.forRoot({}),
    BrowserModule,
    BrowserAnimationsModule,
    AppRouteModule,
    LoginModule,
    FeatureModule,
    ClientDataModule,
    MaterialModule,
    ReactiveFormsModule,    UserIdleModule.forRoot({idle: 1800, timeout: 60, ping: 10}),
    TableModule,
  
  ],
  providers: [
    LoginService, TokenResolverService,
    {provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
 
  
  multi: true},   ],
  entryComponents: [
    SessionExpireAlertComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
 }
