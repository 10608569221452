import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/providers/login.service';
function passwordMatchValidator(password: string): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    }
    return control.parent.get(password).value === control.value ? null : { mismatch: true };
  };
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  forGotPasswordForm: FormGroup;
  hideNewPassword : boolean = true;
  hideConfirmPassword : boolean = true;
  constructor(private formBuilder: FormBuilder, private authService: LoginService, private matSnackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private router: Router) {
  }
  ngOnInit() {
    this.forGotPasswordForm = this.formBuilder.group({
      new_password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      confirm_password: ['', [
        Validators.required,
        passwordMatchValidator('new_password')
      ]],
      token: [this.activatedRoute.snapshot.params['token'], [
        Validators.required
      ]]
    });
  }
  checkPasswords(group: FormGroup) { 
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmedPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  resetPassword() {
    
    if (this.forGotPasswordForm.valid) {
      this.authService.resetPwd(this.forGotPasswordForm.value).subscribe(res => {
        if (res.status) {
          this.matSnackBar.open(res.message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          });
          window.location.href = environment.devServerUrl;
        } else {
          this.matSnackBar.open(res.message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
      });
    } else {
      if(this.forGotPasswordForm.value.new_password && this.forGotPasswordForm.value.confirm_password && (this.forGotPasswordForm.value.new_password != this.forGotPasswordForm.value.confirm_password)){
        this.matSnackBar.open('Password mismatch', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        });
      }
      else{
      this.matSnackBar.open('Please fill all the required fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
}
}
