import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-session-expire-alert',
  templateUrl: './session-expire-alert.component.html',
  styleUrls: ['./session-expire-alert.component.css']
})
export class SessionExpireAlertComponent implements OnInit {
  timeCount: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, cdRef: ChangeDetectorRef, public dialogRef: MatDialogRef<SessionExpireAlertComponent>) {
    data.subscribe((players) => {
      this.timeCount = players;
      cdRef.markForCheck(); 
     });
  }
  ngOnInit() {
  }
  closeModal(status: boolean) {
   this.dialogRef.close(status);
  }
}
