import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientDataComponent } from 'src/modules/client-data/components/client-data/client-data.component';
import { FeatureModule } from '../feature/feature.module';
import { ClientDataRoutingModule } from './client-data-routing.module';
import { MatTableModule } from '@angular/material';
import { MaterialModule } from '../material.module';
import { ReplacePipe } from 'src/app/replace-pipe';@NgModule({
  imports: [
    CommonModule,
    ClientDataRoutingModule,
    FeatureModule,
    MaterialModule,
    FeatureModule
  ],
  declarations: [
    ClientDataComponent
  ]
})
export class ClientDataModule { }
