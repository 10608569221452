import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { LoginRoutingModule } from './login-routing.module';
import { ForgotPasswordDialog, LoginComponent } from 'src/pages/login/login.component';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { ClientRegistrationComponent } from 'src/app/client-registration/client-registration.component';
import { ClientSignupComponent } from 'src/app/client-signup/client-signup.component';@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordDialog,
    ClientRegistrationComponent,
    ClientSignupComponent
  ],
  entryComponents: [
    ForgotPasswordDialog
  ]
})
export class LoginModule { }
