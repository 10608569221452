import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {AppConstants } from 'src/enums/appConstants.enum';
import { sessionStorageKeys } from 'src/enums/session-storage-keys.enum';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/providers/users.service';
import { LoginService } from '../../providers/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public loading = false;
  hide = true;
  constructor(public router: Router, public matSnackBar: MatSnackBar,
    public fb: FormBuilder, public loginApi: LoginService, public dialog: MatDialog, private userService: UsersService, private activatedRoute: ActivatedRoute, private cdRef: ChangeDetectorRef) { }
  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
  forgot() {
    const dialogRef = this.dialog.open(ForgotPasswordDialog, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.matSnackBar.open('Password changed successfully. Please Login with New Password.', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        });
      }
    });
  }
  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      if (this.router.url.replace('/', '') === AppConstants.admin) {
        this.loginApi.adminLogin(this.loginForm.value).subscribe(data => {
          this.loading = false;
          if (data.responseCode === 'EEOO') {
            this.userService.setSessionstorage(sessionStorageKeys.ingen_token, data.data.token);
            this.userService.setSessionstorage(sessionStorageKeys.UserDetails, data.data);
            this.userService.setSessionstorage(sessionStorageKeys.UserRole, 'admin');
            this.router.navigate(['menu/clientData']);
          } else if (data.responseCode === 'ACOO') {
            this.matSnackBar.open('INGENSPARK ACCOUNT HAS BEEN LOCKED, TO GET ACCESS PLEASE CONTINUE WITH FORGET PASSWORD', 'OK', {
             verticalPosition: 'top',
             duration        : 2000,
             panelClass: 'snack-error'
            });
         } else if(data.responseCode === 'F001'||data.responseCode==='SS00'){
            this.matSnackBar.open('INVALID USER NAME OR PASSWORD', 'OK', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-error'
            });
          }
          else if(data.responseCode === 'AC00'){
            this.matSnackBar.open('INVALID USER NAME OR PASSWORD', 'OK', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-error'
            });
          }
        }, error => {
          this.loading = false;
        });
      } else {
        this.loginApi.userLogin(this.loginForm.value).subscribe(data => {
          this.loading = false;
          if (data.responseCode === 'EEOO') {
            this.userService.setSessionstorage(sessionStorageKeys.isLogin, 'self');
            this.userService.setSessionstorage(sessionStorageKeys.ingen_token, data.data.token);
            this.userService.setSessionstorage(sessionStorageKeys.ingen_userId, data.data.userId);
            this.userService.setSessionstorage(sessionStorageKeys.OrgDetails, data.data.fetchOrgDetails);
            this.userService.setSessionstorage(sessionStorageKeys.UserDetails, data.data.fetchUserDetails);
            this.router.navigate(['menu']);
          }  else if(data.responseCode === 'F001'||data.responseCode==='SS00'){
            this.matSnackBar.open('INVALID USER NAME OR PASSWORD', 'OK', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-error'
            });
          }
          else if(data.responseCode === 'AC00'){
            this.matSnackBar.open('YOUR ACCOUNT HAS BEEN LOCKED, PLEASE CONTACT ADMIN', 'OK', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-error'
            });
          }
        }, error => {
          this.loading = false;
        });
      }
    } else {
      this.matSnackBar.open('Please fill required fields..', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
  register() {
    this.router.navigate(['/login/client-registration']);
  }
  loginWithSSO() {
    window.location.assign(environment.loginURL);
  }
}
@Component({
  selector: 'forgot-dialog',
  templateUrl: 'forgot.html',
  styleUrls: ['./login.component.css']
})
export class ForgotPasswordDialog {
  email;
  mailsent = false;
  otpverify = false;
  otp;
  confirmPwd;
  newPwd;
  forGotPasswordForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialog>, public matSnackBar: MatSnackBar, public loginApi: LoginService, private formBuilder: FormBuilder) {
      this.forGotPasswordForm = formBuilder.group({
        email: ['', Validators.required],
        otp: [''],
        newPwd: [''],
        confirmPwd: ['']
      });
     }
  onNoClick(): void {
    this.dialogRef.close();
  }
  sendOtp() {
    if (this.forGotPasswordForm.valid) {
      this.loginApi.sentotp(this.forGotPasswordForm.value.email).subscribe(res => {
        if (res.status) {
          this.mailsent = true;
          this.forGotPasswordForm.controls['otp'].setValidators([Validators.required]);
          this.forGotPasswordForm.controls['otp'].updateValueAndValidity();
        } else {
          this.matSnackBar.open(res.message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
      });
    } else {
      this.matSnackBar.open('Please enter Email ID.', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
  verify() {
    if (this.otp) {
      const reqObj = {
        email: this.email,
        otp: this.otp
      };
      this.loginApi.verifyotp(reqObj).subscribe(res => {
        if (res.status) {
          this.otpverify = true;
          this.mailsent = false;
          this.forGotPasswordForm.controls['newPwd'].setValidators([Validators.required]);
          this.forGotPasswordForm.controls['newPwd'].updateValueAndValidity();
          this.forGotPasswordForm.controls['confirmPwd'].setValidators([Validators.required]);
          this.forGotPasswordForm.controls['confirmPwd'].updateValueAndValidity();
        } else {
          this.matSnackBar.open(res.Message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
      });
    } else {
      this.matSnackBar.open('Please enter OTP.', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
  reset() {
    if (this.newPwd && this.confirmPwd) {
      const reqObj = {
        email: this.email,
        New_password: this.newPwd,
        Conform_password: this.confirmPwd
      };
      this.loginApi.resetPwd(reqObj).subscribe(res => {
        if (res.status) {
          this.dialogRef.close(res);
        } else {
          this.matSnackBar.open(res.Message, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
      });
    } else {
      this.matSnackBar.open('Please enter Password and Confirm Password.', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
}
