import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualScrollDataTableComponent } from 'src/pages/virtual-scroll-data-table/virtual-scroll-data-table.component';
import { MaterialModule } from '../material.module';
import { NgxLoadingModule } from 'ngx-loading';
export let AppInjector: Injector;
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgxLoadingModule
  ],
  declarations: [
    VirtualScrollDataTableComponent
  ],
  exports: [
    VirtualScrollDataTableComponent
  ]
})
export class TableModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
 }
