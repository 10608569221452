import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileComponent } from 'src/pages/my-profile/my-profile.component';

const routes: Routes = [
  {path: '', component: MyProfileComponent}
];@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
