import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty && control.parent.touched);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty && control.parent.touched);
    return (invalidCtrl || invalidParent);
  }
}
@Component({
  selector: 'app-client-signup',
  templateUrl: './client-signup.component.html',
  styleUrls: ['./client-signup.component.css']
})
export class ClientSignupComponent implements OnInit {
  signUpForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  clientId: number;
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  constructor(private formBuilder: FormBuilder, public ac: ActivatedRoute) { }
  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      clientFirstName: ['', Validators.required],
      clientLastName: ['', Validators.required],
      clientUserName: ['', Validators.required],
      clientEmail: ['', [Validators.required, Validators.email]],
      clientCompanyName: ['', Validators.required],
      clientAlliasName: ['', Validators.required],
      userPsw: ['', Validators.required],
      confirmPwd: ['', Validators.required],
      PostgreSQLDB: ['', Validators.required],
      ArangoDB: ['', Validators.required],
      S3Bucket: ['', Validators.required]
    }, { validator: this.checkPasswords });
    this.clientId = this.ac.snapshot.queryParams.teamId;
  }
  addClient() {
  }
  get f() { return this.signUpForm.controls; }
  checkPasswords(group: FormGroup) {
  const pass = group.controls.userPsw.value;
  const confirmPass = group.controls.confirmPwd.value;
  return pass === confirmPass ? null : { notSame: true };
}
}
