export enum GraphsList {
    horizantalBar = 'Hbar'
}
export enum AppConstants {
    admin = 'admin',
    UPDATED = 'updated',
    NON_UPDATED = 'non_updated'
}

export enum QueryParams {
    PERFORMACE_DRIVER_ID = 'performanceDriverId',
    FILE_ID = 'fileId',
    TABLE_NAME = 'tableName',
    SET_UP_ID = 'setUpId'
}

export class ApiName {

}
