import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AdminService } from 'src/providers/admin.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html', animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./client-data.component.css']
})
export class ClientDataComponent implements OnInit {
  clientsDetails = [];
  dataSource = [];
  columnsToDisplay = ['client_first_name', 'client_last_name', 'client_email', 'client_company_name', 'action'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: ClientData | null;
  constructor(public adminService: AdminService, private matSnackBar: MatSnackBar) { }
  ngOnInit() {
    this.getClientsData();
  }
  getClientsData() {
    this.adminService.getClientsData().subscribe(res => {
      this.clientsDetails = res;
      this.dataSource = res;
    });
  }
  acceptClient(status, id) {
    this.adminService.acceptClient(status, id).subscribe(res => {
      if (res) {
        this.getClientsData();
        this.matSnackBar.open('Client has got approval successfully.', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        });
      } else {
        this.matSnackBar.open(res, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        });
      }
    });
  }
  stopAccess() {
    this.matSnackBar.open('Please try again later.', 'OK', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    });
  }
}
interface ClientData {
    action_perform: boolean;
addressline1: string;
addressline2: string;
city: string;
client_alias_name: string;
client_company_name: string;
client_email: string;
client_first_name: string;
client_last_name: string;
client_mail_trigger_dttm: string;
client_phone_no: string;
client_username: string;
cmpid: number;
country_name: string;
gender: string;
ingen_connect: boolean;
ingen_customer: boolean;
ingen_data: boolean;
ingen_measures: boolean;
ingen_optim: boolean;
ingen_tracker: boolean;
join_date: string;
jwt_token: string;
management_approval: false;
management_approved_dttm: string;
pincode: string;
sso: boolean;
state_name: string;
validate: boolean;
validate_dttm: string;
  }
