
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
// import { AuthService } from './services/auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { UsersService } from './users.service';
import { MatSnackBar } from '@angular/material';
import { sessionStorageKeys } from 'src/enums/session-storage-keys.enum';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private matSnackBar: MatSnackBar, private loginService: LoginService, private userService: UsersService) { }
    private handleAuthError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // handle auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            // navigate /delete cookies or whatever
            // return this.handle401Error(request, next);
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            // return Observable.throw(err.error.message); // or EMPTY may be appropriate here
        } else {
          this.matSnackBar.open('SERVER ERROR.', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          });
        }
        return throwError(err);
    }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.userService.getToken()) {
      request = this.addToken(request, this.userService.getToken());
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x, request, next)));
    // return next.handle(request).pipe(catchError(error => {
    //   if (error instanceof HttpErrorResponse && error.status === 401) {
    //     // return this.handle401Error(request, next);
    //   } else {
    //     // return throwError(error);
    //   }
    // }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const httpHeaders = {
      'Authorization': 'Basic ' + btoa(this.userService.getUserId() + ':' + token),
      'Permissions-Policy': 'camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*',
      'Strict-Transport-Security': 'max-age=31536000; includeSubdomains',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'X-Xss-Protection': '1; mode=block',
      'Content-Type': 'application/json; charset=utf-8',
      'Content-Security-Policy': 'script-src https: \'unsafe-inline\' \'unsafe-eval\';style-src https: \'unsafe-inline\' \'unsafe-eval\';img-src https: data:;font-src https: data:;'
    };
    // ignore setting content type FormData
    const ignore = request.body && request.body.toString() === '[object FormData]';
    if (ignore) {
      delete httpHeaders['Content-Type'];
    }
    return request.clone({
      setHeaders: httpHeaders
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.loginService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.userService.setSessionstorage(sessionStorageKeys.ingen_token, token.token);
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
