import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { DeleteConfirmationModule } from '../delete-confirmation/delete-confirmation.module';
import { ReplacePipe } from 'src/app/replace-pipe';@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    DeleteConfirmationModule
  ],
  declarations: [
    ReplacePipe
  ]
  , exports: [
    ReplacePipe
  ]
})
export class FeatureModule { }
