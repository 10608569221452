import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/providers/admin.service';
import { ActivatedRoute,Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UsersService } from 'src/providers/users.service';
import { NotificationService } from 'src/providers/notification.service';
import { refreshModel } from 'src/models/refresh.model';
import { onGoingProcess } from 'src/models/ongoing.model';
import { notificationItenFunctions, notificationRefreshStrings } from 'src/enums/stringItems.enum';
import { actionSheetData, actionsRes, notificationItem, notificationsRes } from 'src/models/notifications.model';
import { PasswordChangemodalComponent } from 'src/modals/password-changemodal/password-changemodal.component';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  userDetails: any;
  userDetail: any;
  username: any;
  isAdmin: boolean;
  url: any;
  loading: boolean;
  notifications: notificationsRes = new notificationsRes();
  unreadMessagesCount: any;
  private refreshDataSubscription: Subscription;
  allUserBadges: any = [];
  actionsList: actionsRes = new actionsRes;
  onGoingProcessList: onGoingProcess[] = [];
  @ViewChild ('fileInput') fileInput:ElementRef<HTMLInputElement>
  constructor(public adminService: AdminService, public router: Router,public ac: ActivatedRoute, public matSnackBar: MatSnackBar,public notificationService: NotificationService, private userService: UsersService) { }
  ngOnInit() {
    this.getMyProfile();
    if (!this.isAdmin) {
      this.notificationService.intialize();
      this.getNotifications();
      this.getAllUnreadMessagesCount();
      this.getRefreshData();
      this.getActions();
      this.getProcessStatus();
      this.username = this.userService.getUserDetails().userName;
      this.userDetail = this.userService.getUserDetails();
      console.log(this.userDetail);
    } else {
      this.username = this.userService.getUserDetails().managementMail;
    }
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = event.target.result;
      };
    }
  }

  getNotifications() {
    this.notificationService.getAllNotifications().subscribe((res: notificationsRes) => {
      this.notifications = res;
    });
  }

  getAllUnreadMessagesCount() {
    this.notificationService.getUnreadMessagesCount().subscribe(res => {
      this.unreadMessagesCount = res.data;
      this.notificationService.chatBoxCount = res.data;
    });
  }

  getActions() {
    this.notificationService.getAllActions().subscribe((res: actionsRes) => {
      this.actionsList = res;
    });
  }

  getRefreshData() {
    this.refreshDataSubscription = this.notificationService.getRefreshData().subscribe((res: refreshModel) => {
      if (res.actionstring === notificationRefreshStrings.refresh_ongoing_process_api) {
        this.getProcessStatus();
      } else if (res.actionstring === notificationRefreshStrings.refresh_file_action_data_api) {
        this.matSnackBar.open('You have received new notification', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        });
        this.getNotifications();
        this.getActions();
      } else if (res.actionstring === notificationRefreshStrings.refresh_notifications) {
        this.getNotifications();
      }
    });
  }

  getProcessStatus() {
    this.notificationService.getOnGoingProcess().subscribe((res: onGoingProcess[]) => {
      this.onGoingProcessList = res;
    });
  }

  saveProfile() {
    const fd = new FormData();
    const fileUpload = this.fileInput.nativeElement;
    if (fileUpload) {
      this.loading = true;
      fd.append('file', fileUpload.files[fileUpload.files.length - 1]);
      this.adminService.updateProfile(fd).subscribe(res => {
        if (res.data) {
          this.getMyProfile();
          this.loading=false
          this.matSnackBar.open('Profile changed succesfully','ok',{
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
        }
      }, error => {
        this.loading = false;
        this.matSnackBar.open('server error', 'OK',{
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        });
      });
    }
  }
  getMyProfile() {
    this.loading = true;
    this.adminService.getMyprofile().subscribe(res => {
      if(res.data){
      this.userDetails = res.data;
      this.notificationService.UpdateUserDetails(this.userDetails);
    
      }
      else{
        this.matSnackBar.open('Server Error','ok',{
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
      this.loading = false;
    });
  }
  changePassword() {
    // const dialogRef = this.dialog.open(PasswordChangemodalComponent, {
    //   width: '400px'
    // });
  }
}
