import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators'; import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { UsersService } from './users.service'; @Injectable()
export class LoginService {
  encryptedone: any;
  Encryptedreqobj: any;
  currentencryptedone: { confirm_password: any; new_password: any };

  constructor(private http: HttpClient, private userService: UsersService) { }
  encryptCredentials(username: string, password: string): string {
    //   const secretKey = 'ABCDEFGHIJKLMNOP';

    //   const encryptedUsername = username;
    //   const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    //   this.encryptedone = {username: encryptedUsername,
    //     password: encryptedPassword
    // }

    // return JSON.stringify({
    //   username: encryptedUsername,
    //   password: encryptedPassword
    // });


    let key = CryptoJS.enc.Utf8.parse("ABCDEFGHIJKLMNOP");
    let iv = CryptoJS.enc.Utf8.parse("98a465f12245897b");
    let encryptedPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
    console.log(encryptedPassword);
    return JSON.stringify({
      userName: username,
      password: encryptedPassword
    });

  }


  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error}`;
      console.log("ereeewewwe")
    } else {
      errorMessage = error.error.responseMessage;
    } return throwError(errorMessage);
  }


  // userLogin(reqData) {
  //   return this.http.post(environment.LOGIN_API_ENDPOINT + 'login', {'userName': reqData.username, 'password': reqData.password})
  //       .pipe(map((response: any) => response), catchError(this.handleError));
  // } 
  //  adminLogin(reqData) {
  //   return this.http.post(environment.CLIENT_MANAGEMENT_ENDPOINT + 'adminLogin', {'userName': reqData.username, 'password': reqData.password})
  //       .pipe(map((response: any) => response), catchError(this.handleError));
  // }


  userLogin(reqData) {
    const encryptedCredentials = this.encryptCredentials(reqData.username.toLowerCase(), reqData.password);

    return this.http.post(environment.LOGIN_API_ENDPOINT + 'login', encryptedCredentials, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(map((response: any) => response), catchError(this.handleError));
  }

  adminLogin(reqData) {
    const encryptedCredentials = this.encryptCredentials(reqData.username.toLowerCase(), reqData.password);

    return this.http.post(environment.LOGIN_API_ENDPOINT + 'adminLogin', encryptedCredentials, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(map((response: any) => response), catchError(this.handleError));
  }

  userLogout() {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'logOut?userId=' + this.userService.getUserId())
      .pipe(map((response: any) => response));
  } sentotp(reqData) {
    const body = new URLSearchParams();
    body.set('email', reqData);
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'forgot_psw?email=' + reqData, '')
      .pipe(map((response: any) => response), catchError(this.handleError));
  } verifyotp(reqData) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'reset_psw?email=' + reqData.email + '&otp=' + reqData.otp)
      .pipe(map((response: any) => response), catchError(this.handleError));
  }

  resetPwd(reqData: any) {
    this.Encryptedreqobj = this.changePasswordEncryptcredentials(reqData.confirm_password, reqData.new_password)
    reqData.confirm_password = this.Encryptedreqobj.confirm_password;
    reqData.new_password = this.Encryptedreqobj.new_password;

    return this.http.post(environment.PYTHON_API_ENDPOINT + 'reset_psw', reqData)
      .pipe(map((response: any) => response));
  }

  changePasswordEncryptcredentials(confirm_password: string, new_password: string) {
    let key = CryptoJS.enc.Utf8.parse("ABCDEFGHIJKLMNOP");
    let iv = CryptoJS.enc.Utf8.parse("98a465f12245897b");
    let encryptedCPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(confirm_password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
    let encryptedNPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(new_password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();

    this.currentencryptedone = {
      confirm_password: encryptedCPassword,
      new_password: encryptedNPassword,
    }
    return this.currentencryptedone

  }

  searchItems(reqData) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'searchtext?org_id=' + this.userService.getOrgId() + '&texts=' + reqData)
      .pipe(map((response: any) => response), catchError(this.handleError));
  } searchDetails(reqData) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'search_details?id=' + reqData.id + '&type=' + reqData.type)
      .pipe(map((response: any) => response), catchError(this.handleError));
  }
  sendClientData(data) {
    return this.http.post(environment.CLIENT_MANAGEMENT_ENDPOINT + 'insertClientData', data)
      .pipe(map((response: any) => response));
  }
  refreshToken() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_user_token?user_login_id=' + this.userService.getUserId())
      .pipe(map((response: any) => response));
  }
  getUserDetails() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_user_data')
      .pipe(map((response: any) => response));
  }
}
