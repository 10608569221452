import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import {filter} from 'rxjs/operators';
import {map, mergeMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { connectionSetupModalData } from 'src/models/connections.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ingenspark';
  activeRoute: string;
  page = '';
  pageTitle = '';
  headerClasses: any;
  constructor(private router: Router,    private route: ActivatedRoute) {
    this.router.events.subscribe((res) => {
      this.activeRoute = router.url;
  });

  // document.addEventListener('contextmenu', function(e) {
  //   e.preventDefault();
  // });
   }
ngOnInit(): void {
  }

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(e: KeyboardEvent) {
  //   console.log(e)
  //   if (e.key === 'F12') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "I") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "C") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "J") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.key == "U") {
  //     return false;
  //   }
  //   return true;
  // }

}
