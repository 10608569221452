import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { DeleteConfirmationRoutingModule } from './delete-confirmation-routing.module';
import { DeleteConfirmationModalComponent } from 'src/modules/delete-confirmation/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { MaterialModule } from '../material.module';
import { DeleteProjectComponent } from './components/delete-project/delete-project.component';
import { ResetPasswordComponent } from 'src/reset-password/reset-password.component';
import { FormsModule } from '@angular/forms';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DeleteConfirmationRoutingModule,
    FormsModule
  ],
  declarations: [
    DeleteConfirmationModalComponent,
    DeleteProjectComponent,
    DeleteConfirmationComponent,
    
  ],
  entryComponents: [
    DeleteConfirmationModalComponent,
    DeleteProjectComponent,
    DeleteConfirmationComponent
  ]
})
export class DeleteConfirmationModule { }
