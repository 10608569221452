export enum sessionStorageKeys {
projectData= 'projectData',
isLogin= 'isLogin',
ingen_token= 'ingen_token',
ingen_userId= 'ingen_userId',
OrgDetails= 'OrgDetails',
UserDetails= 'UserDetails',
UserRole = 'userRole',
campaign_id = 'campaign_id',
performance_drivers_id = 'performance_drivers_id',
user_file_upload_id = 'user_file_upload_id',
marketing_mix_id = 'marketing_mix_id'
}
