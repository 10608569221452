import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; import { ProfileRoutingModule } from './profile-routing.module';
import { MyProfileComponent } from 'src/pages/my-profile/my-profile.component';
import { NgxLoadingModule } from 'ngx-loading';
import { MaterialModule } from '../material.module';
import { PasswordChangemodalComponent } from 'src/modals/password-changemodal/password-changemodal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    ProfileRoutingModule,
    NgxLoadingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MyProfileComponent,
    PasswordChangemodalComponent],
  entryComponents: [
    PasswordChangemodalComponent
  ],
  exports: [
    PasswordChangemodalComponent
  ]
})
export class ProfileModule { }
