import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientDataComponent } from 'src/modules/client-data/components/client-data/client-data.component';
const routes: Routes = [
    {path: '', component: ClientDataComponent}
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ClientDataRoutingModule { }
