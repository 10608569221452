import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientRegistrationComponent } from 'src/app/client-registration/client-registration.component';
import { ComponentName } from 'src/enums/componentName.enum';
import { LoginComponent } from 'src/pages/login/login.component';const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: ComponentName.CLIENT_REGISTRATION, component: ClientRegistrationComponent}
];@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
