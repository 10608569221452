import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { sessionStorageKeys } from 'src/enums/session-storage-keys.enum';@Injectable({
  providedIn: 'root'
})
export class UsersService {
constructor(private matSnackBar: MatSnackBar) {}

public pageType: BehaviorSubject<string> = new BehaviorSubject<string>('');
private triggerMethodSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

savePageType(data:any){
  this.pageType.next(data)
}

triggerMethod() {
  this.triggerMethodSubject.next(true);
} 
getMethodTrigger() {
  return this.triggerMethodSubject.asObservable();
}
  getValidateUser(): boolean {
    if (sessionStorage.getItem(sessionStorageKeys.ingen_token) && sessionStorage.getItem(sessionStorageKeys.UserDetails)) {
      return true;
    } else {
      return false;
    }
  }
  // getUserId() {
  //   return JSON.parse(sessionStorage.getItem(sessionStorageKeys.UserDetails)).userLoginId;
  // }
  // getOrgId() {
  //   return JSON.parse(sessionStorage.getItem(sessionStorageKeys.UserDetails)).orgId;
  // }

  getUserId() {
    const userDetails = JSON.parse(sessionStorage.getItem(sessionStorageKeys.UserDetails));
    if (userDetails) {
      return userDetails.userLoginId;
    } else {
      // Handle the case where userDetails is null or undefined
      console.error('User details are not available in session storage');
      return null;
    }
  }

  getOrgId() {
    const userDetails = JSON.parse(sessionStorage.getItem(sessionStorageKeys.UserDetails));
    if (userDetails) {
      return userDetails.orgId;
    } else {
      // Handle the case where userDetails is null or undefined
      console.error('User details are not available in session storage');
      return null;
    }
  }
  getProjectId() {
    if (JSON.parse(sessionStorage.getItem(sessionStorageKeys.projectData))) {
      return JSON.parse(sessionStorage.getItem(sessionStorageKeys.projectData)).projectId;
    }
    return null;
  }
  getProjectData() {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.projectData));
    }
  getToken() {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.ingen_token));
  }
  getUserDetails() {
  return JSON.parse(sessionStorage.getItem(sessionStorageKeys.UserDetails));
  }
  clearSession() {
    sessionStorage.clear();
  }
  removeSessionItem(item) {
    sessionStorage.removeItem(item);
  }
  setSessionstorage(key: sessionStorageKeys, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  checkProjectStatus() {
    if (JSON.parse(sessionStorage.getItem(sessionStorageKeys.projectData))) {
      return true;
    }
    this.matSnackBar.open('Please save project and continue.', 'OK', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    });
  }

  removeSessionstorage(key: string): void {
    sessionStorage.removeItem(key);
  }
}
