import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {  Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {AppInjector} from 'src/app/app.module';
import { IngenCustomerService } from 'src/providers/ingen-customer.service';
export class MyDataSource extends DataSource<any | undefined> {
  private _length = 100000;
  private _pageSize = 100;
  private _cachedData = Array.from<any>({ length: this._length });
  private _fetchedPages = new Set<number>();
  private readonly _dataStream = new BehaviorSubject<(any | undefined)[]>(
    this._cachedData
  );
  private readonly _subscription = new Subscription();
tableName: string;
schema: number;
myService = AppInjector.get(IngenCustomerService);
displayedColumns = [];
  connect(
    collectionViewer: CollectionViewer
  ): Observable<(any | undefined)[]> {
    this._subscription.add(
      collectionViewer.viewChange.subscribe(range => {
        const startPage = this._getPageForIndex(range.start);
        const endPage = this._getPageForIndex(range.end - 1);
        for (let i = startPage; i <= endPage; i++) {
          this._fetchPage(i);
        }
      })
    );
    return this._dataStream;
  }
  disconnect(): void {
    this._subscription.unsubscribe();
  }
  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }
  private _fetchPage(page: number) {
    if (this._fetchedPages.has(page)) {
      return;
    }
    this._fetchedPages.add(page);
    setTimeout(() => {
      this.myService.getSampleTableData(this.tableName, page * 100, this.schema).subscribe(res => {
        if (res.status) {
          this._length = res.count;
          this.displayedColumns = Object.keys(res.data[0]);
          this._cachedData.splice(page * this._pageSize, this._pageSize, ...res.data);
          this._dataStream.next(this._cachedData);
        }
      });
    }, 1000);
  }
  sendTableName(tableName, count, data, schema) {
    this._length = count;
    this.tableName = tableName;
    this.schema = schema;
            this._cachedData = Array.from<any>({ length: count });
      this._cachedData.splice(0 * this._pageSize, this._pageSize, ...data);
      this._dataStream.next(this._cachedData);
  }
}
@Component({
  selector: 'app-virtual-scroll-data-table',
  templateUrl: './virtual-scroll-data-table.component.html',
  styleUrls: ['./virtual-scroll-data-table.component.css']
})
export class VirtualScrollDataTableComponent implements OnInit, OnChanges {
  ds = new MyDataSource();
  displayedColumns = [];
 @Input() tableName: string;
 @Input() schema: number;
 loading: boolean;
  constructor(private ingenCustomerService: IngenCustomerService) {
  }
  ngOnInit() {
    this.getTableData();
  }
  ngOnChanges(changes) {
    if (changes['tableName'].currentValue) {
      this.tableName = changes['tableName'].currentValue;
    }
  }
  getTableData() {
    this.loading = true;
    this.ingenCustomerService.getSampleTableData(this.tableName, 0, this.schema).subscribe(res => {
      this.loading = false;
      if (res.status) {
        this.displayedColumns = Object.keys(res.data[0]);
        this.ds.sendTableName(this.tableName, res.count, res.data, this.schema);
      }
    });
  }
  refreshTableData() {
    this.ds = new MyDataSource();
    this.displayedColumns = [];
    this.getTableData();
  }
}

