import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';import {environment} from 'src/environments/environment';
import { UsersService } from './users.service';
import * as CryptoJS from 'crypto-js'; 
@Injectable({
  providedIn: 'root'
})

export class AdminService {
  Encryptedreqobj: any;
  currentencryptedone: { confirm_password: any;new_password: any;old_password: any};
  constructor(private http: HttpClient, private userService: UsersService) { }  
encryptedone: any; 
encryptCredentials(password: string,confirmPwd:string): string {
//   const secretKey = 'ABCDEFGHIJKLMNOP';

  
//   const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
//   const encryptedCPassword = CryptoJS.AES.encrypt(confirmPwd, secretKey).toString();
//   this.encryptedone = {
//     password: encryptedPassword,
//     confirmPwd:encryptedCPassword
// }
  
//   return this.encryptedone
    
let key = CryptoJS.enc.Utf8.parse("ABCDEFGHIJKLMNOP");
let iv = CryptoJS.enc.Utf8.parse("98a465f12245897b");
let encryptedPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
let encryptedCPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(confirmPwd), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();

console.log(encryptedPassword);
this.encryptedone = {
      password: encryptedPassword,
      confirmPwd:encryptedCPassword
  }
    
    return this.encryptedone 
 
  
}
changePasswordEncryptcredentials(confirm_password: string,new_password:string,old_password:string){
  let key = CryptoJS.enc.Utf8.parse("ABCDEFGHIJKLMNOP");
  let iv = CryptoJS.enc.Utf8.parse("98a465f12245897b");
  let encryptedCPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(confirm_password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
  let encryptedNPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(new_password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
  let  encryptedOPassword= CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(old_password), key, { iv: iv, mode: CryptoJS.mode.CFB }).toString();
  
  
  this.currentencryptedone = {
        confirm_password: encryptedCPassword,
        new_password:encryptedNPassword,
        old_password:encryptedOPassword,
       
    }
      
      return this.currentencryptedone
   
}
getLocationDetails() {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'fetchOrgLocations', {
      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId(),
    })
        .pipe(map((response: any) => response));
  }  getCompanyDetails() {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'fetchOrgDetails', {
      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId()
    })
        .pipe(map((response: any) => response));
  }  editCompany(orgForm) {
    const reqObj = {
      'address': orgForm.address,
  'categoryOfBusiness': orgForm.categoryOfBusiness,
  'landLineNo': orgForm.landLineNo,
  'noOfEmployees': orgForm.noOfEmployees,
  'orgId': this.userService.getOrgId(),
  'orgMailId': orgForm.orgMailId,
  'orgName': orgForm.orgName,
  'noOfBranches': orgForm.noOfBranches,
  'userLoginId': this.userService.getUserId()
    };    return this.http.post(environment.LOGIN_API_ENDPOINT + 'updateOrgDetails', reqObj)
        .pipe(map((response: any) => response));
  }  getTeams(reqObj) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'fetchOrgTeams', {
      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId(),
      'orgLocationId': reqObj.orgLocationId,
      'orgTeamId': reqObj.orgTeamId,
      'teamNm': reqObj.teamNm
    })
        .pipe(map((response: any) => response));
  }
  getTeam(teamId) {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'fetchSingleTeam?teamId=' + teamId)
    .pipe(map((response: any) => response));
  }
  createTeam(teamForm) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'insertOrgTeams', {
      'isactive': true,
      'locationNm': teamForm.locationNm,
      'noFfEmployees': teamForm.noFfEmployees,
      'orgId': teamForm.orgId,
      'orgLocationId': teamForm.orgLocationId,
      'teamDescription': teamForm.teamDescription,
      'teamNm': teamForm.teamNm,
      'userLoginId': teamForm.userLoginId
    })
        .pipe(map((response: any) => response));
  }  updateTeam(teamForm) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'updateOrgTeams', {
      'isactive': true,
      'locationNm': teamForm.locationNm,
      'noFfEmployees': teamForm.noFfEmployees,
      'orgId': teamForm.orgId,
      'orgLocationId': teamForm.orgLocationId,
      'orgTeamId': teamForm.orgTeamId,
      'teamDescription': teamForm.teamDescription,
      'teamNm': teamForm.teamNm,
      'userLoginId': teamForm.userLoginId
    })
        .pipe(map((response: any) => response));
  }  addLocations(locationForm, orgLocationsId) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'insertOrUpdateOrgLocations', {
      'address': locationForm.location,
      'branchNm': locationForm.branchNm,
      'natureOfActivity': locationForm.natureOfActivity,
      'noOfEmployees': locationForm.noOfEmployees,
      'countryNm': locationForm.countryNm,
      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId(),
      'orgLocationsId': JSON.parse(orgLocationsId)
    }).pipe(map((response: any) => response));  }  

    addEmployee(reqObj) {
      
      console.log(reqObj)
      this.Encryptedreqobj=this.encryptCredentials(reqObj.userPsw,reqObj.confirmPwd)
      console.log( this.Encryptedreqobj)
      reqObj.confirmPwd=this.Encryptedreqobj.confirmPwd,
      reqObj.userPsw=this.Encryptedreqobj.password,
      console.log( reqObj);

    const body = {
      'insertUser': reqObj,
      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId(),
    };
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'saveOrganisationAndUserData', body)
        .pipe(map((response: any) => response,
     
         
        ));
  }  getEmployees() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_org_level_users_v1?org_id='+this.userService.getOrgId()
     
      // 'userLoginId': this.userService.getUserId(),
    )
        .pipe(map((response: any) => response));
  }  editEmployee(reqObj) {
    const body = {
        'designation': reqObj.form.designation,
        'emailId': reqObj.form.emailId,
        'firstNm': reqObj.form.firstNm,
        'mobileNo': reqObj.form.mobileNo,
        'orgId': this.userService.getOrgId(),
        'userLoginId': this.userService.getUserId(),
        'userName': reqObj.form.userName,
        'userPsw': reqObj.form.userPsw,
        'userType': reqObj.form.userType    };    return this.http.post(environment.LOGIN_API_ENDPOINT + 'updateUserDetails', body)
        .pipe(map((response: any) => response));
  }  getEmployeeDetails(reqObj) {
    const body = {
      'orgId': reqObj.orgid,
      'userLoginId': reqObj.userLoginId,
    };    return this.http.post(environment.LOGIN_API_ENDPOINT + 'fetchUserDetails', body)
        .pipe(map((response: any) => response));
  }  checkAvailability(name) {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'chackUserNameAvailabulity?userName=' + name)
        .pipe(map((response: any) => response));
  }
  deleteLocation(locId, userType) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'deleteLocations?locationId=' + locId + '&userLoginId=' + this.userService.getUserId() + '&userType=' + userType, '')
        .pipe(map((response: any) => response));
  }
  deleteTeams(teamId, userType) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'deleteTeams?teamId=' + teamId + '&userLoginId=' + this.userService.getUserId() + '&userType=' + userType, '')
        .pipe(map((response: any) => response));
  }
  deleteEmoployee(employeeId, userType) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'deleteUser?orgId=' + this.userService.getOrgId() + '&userId=' + employeeId + '&userType=' + userType, '')
        .pipe(map((response: any) => response));
  }
  postBadge(badgeName, file, userType, badgeId) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'uploadOrUpdateBadge?badgeName=' + badgeName + '&orgId=' + this.userService.getOrgId() + '&userId=' + this.userService.getUserId() + '&userType=' + userType + '&badgeId=' + badgeId, file)
        .pipe(map((response: any) => response));
  }
  getAllBadges() {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'fetchBadge?orgId=' + this.userService.getOrgId()).pipe(map((responce: any) => responce));
  }
  deleteBadge(badgeId) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'deleteBadge?badgeId=' + badgeId + '&orgId=' + this.userService.getOrgId() + '&userLoginId=' + this.userService.getUserId() , '')
    .pipe(map((response: any) => response));
  }
  getBadgeDetails(badgeId) {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'fetchSingleBadge?badgeId=' + badgeId).pipe(map((responce: any) => responce));
  }
  updateProfile(fd) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'uploadOrUpdateProfilePic?userId=' + this.userService.getUserId() + '&orgId=' + this.userService.getOrgId(), fd)
    .pipe(map((response: any) => response));
  }
  getMyprofile() {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'myProfileData?orgId=' + this.userService.getOrgId() + '&userId=' + this.userService.getUserId(), '')
    .pipe(map((response: any) => response));
  }
  changePassword(reqData: any) {
    console.log(reqData)
    this.Encryptedreqobj=this.changePasswordEncryptcredentials(reqData.confirm_password,reqData.new_password,reqData.old_password)
    console.log( this.Encryptedreqobj)
    reqData.confirm_password=this.Encryptedreqobj.confirm_password,
    reqData.new_password=this.Encryptedreqobj.new_password,
    reqData.old_password=this.Encryptedreqobj.old_password,
    

    console.log( reqData);

    return this.http.post(environment.PYTHON_API_ENDPOINT + 'change_password', reqData)
    .pipe(map((response: any) => response));
  }  postClassData(className) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'post_class_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&class_names=' + className, '')
    .pipe(map((response: any) => response));
  }
  postUserCustomClassData(className) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'post_user_custom_class_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_custom_class_name=' + className + '&project_id=' + this.userService.getProjectId(), '')
    .pipe(map((response: any) => response));
  }
  editClassData(className, classId, isCustom) {
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'put_class_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&class_name=' + className + '&class_id=' + classId + '&is_custom_class=' + isCustom, '')
    .pipe(map((response: any) => response));
  }
  getClass() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'class_data_org_id?org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  getCustomAndOrgClasses() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_user_custom_class_and_org_class_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  addProduct(productData) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'post_product_class_data', {
      'user_login_id': this.userService.getUserId(),
      'org_id': this.userService.getOrgId(),
      'data': productData,
    })
    .pipe(map((response: any) => response));
  }
  getProducts() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_product_class_data?org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  getProductDetails(productId, classId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_product_data?product_id=' + productId + '&class_id=' + classId + '&org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  deleteProduct(json:any) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'send_request_to_delete_cls_prod',json )
    .pipe(map((response: any) => response));
  }
  editProduct(productId, productName, productClass, productOwnerId, classId) {
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'put_product_class_data', {
      'user_login_id': this.userService.getUserId(), 
      'product_id': productId,
      'product_name': productName,
      'class_id': productClass,
        'user_id': productOwnerId,
        'org_id': this.userService.getOrgId(),
        'class_need_to_remove': classId    })
    .pipe(map((response: any) => response));
  }
  deleteClass(classId, classUserId, isCustom) {
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_class_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_id=' + classUserId + '&class_id=' + classId + '&is_custom_class=' + isCustom)
    .pipe(map((response: any) => response));
  }
  getSingleClassDetails(classId, isCustom) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_class_data?org_id=' + this.userService.getOrgId() + '&class_id=' + classId + '&is_custom_class=' + isCustom)
    .pipe(map((response: any) => response));
  }
  getClassWiseProducts(fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'check_file_class_wise_products?user_file_upload_id=' + fileId + '&org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  getClientsData() {
    return this.http.get(environment.CLIENT_MANAGEMENT_ENDPOINT + 'getRegisteredClientDetails')
    .pipe(map((response: any) => response));
  }
  acceptClient(status, id) {
    return this.http.post(environment.CLIENT_MANAGEMENT_ENDPOINT + 'managementApproval?acceptorreject=' + status + '&cmpId=' + id, '')
    .pipe(map((response: any) => response));
  }
  sendChangePasswordLink(emailId){
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'change_passwords?email='+emailId,'')
    .pipe(map((response: any) => response));
  }
  getAdminDashboardData(){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'total_no_users_upto_last_week?org_id='+this.userService.getOrgId()+'&user_login_id='+this.userService.getUserId())
    .pipe(map((response: any) => response));
  }
  getOrgLevelProjects(){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_org_level_projects?org_id='+this.userService.getOrgId()+'&user_login_id='+this.userService.getUserId())
    .pipe(map((response: any) => response));
  }
  deleteproduct(productId, productUserId, classId, className) {
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_product_class_data?user_login_id=' + this.userService.getUserId() + '&product_id=' + productId + '&user_id=' + productUserId + '&org_id=' + this.userService.getOrgId() + '&class_id=' + classId + '&class_name=' + className)
    .pipe(map((response: any) => response));
  }
  getActivityLogs(P_id:any){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_user_activity_logs?user_login_id=' + this.userService.getUserId() + '&project_id=' + P_id )
    .pipe(map((response: any) => response));
  }
  getAllProjects(){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_all_projects?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() )
    .pipe(map((response: any) => response));
  }
  getActions(action:any){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'filter_action?org_id=' + this.userService.getOrgId()+'&action=' + action)
    .pipe(map((response: any) => response));
  }
  rejectUserRequest(json:any){
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'manage_product_class',json)
    .pipe(map((response: any) => response));
  }
  
  approveUserRequest(json:any){
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'manage_product_class',json)
    .pipe(map((response: any) => response));
  }
  getRejectedUserRequests(){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'fetch_rejection_data?org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }

}
