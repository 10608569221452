import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoginService } from 'src/providers/login.service';
export interface responseType {
  message: any;
  status: boolean;
}
@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.css']
})
export class ClientRegistrationComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  response: responseType;
  showAlert: boolean;
  intialValue: any;
  loading: boolean;
  constructor(private formBuilder: FormBuilder, public loginApi: LoginService, public matSnackBar: MatSnackBar, private router: Router) { 
    this.registerForm = this.formBuilder.group({
      clientFirstName: ['', [Validators.required,this.characterValidator()]],
      clientLastName: ['', [Validators.required,this.characterValidator()]],
      clientUserName: ['', [Validators.required, this.noSpacesValidator()]],
      clientEmail: ['', [Validators.required, Validators.email]],
      clientCompanyName: ['', [Validators.required]],
      clientAlliesName: ['', [Validators.required]],
      clientPhoneNumber: ['', [Validators.required, this.noSpacesValidator(),this.phoneNumberValidator()]],
      countryName: ['', [Validators.required,this.characterValidator()]],
      stateName: ['', [Validators.required,this.characterValidator()]],
      city: ['', [Validators.required,this.characterValidator()]],
      pincode: ['', [Validators.required, this.noSpacesValidator()]],
      addressline1: [''],
      addressline2: [''],
      ingenData: [false],
      ingenConnect: [false],
      ingenCustomer: [false],
      ingenOptim: [false],
      ingenTracker: [false],
      ingenMeasures: [false],
      loginWithSSO: [true, [Validators.required]]
    });
  }
  ngOnInit() {
    
  }
  get f() { return this.registerForm.controls; }
  addClient() {
      if (this.registerForm.invalid) {
        this.matSnackBar.open('Please fill all required fields.', 'OK', {
          verticalPosition: 'top',
          duration        : 2000,
          panelClass: 'snack-error'
      });
      } else {
        this.loading = true;
        this.loginApi.sendClientData(this.registerForm.value).subscribe(res => {
          this.loading = false;
          this.response = res;
          this.showAlert = true;
          if (res.status) {
            this.matSnackBar.open(res.message, 'OK', {
              verticalPosition: 'top',
              duration        : 5000,
              panelClass: 'snack-success'
          });
          this.router.navigate(['/login']);
          } else {
            this.matSnackBar.open(res.message, 'OK', {
              verticalPosition: 'top',
              duration        : 5000,
              panelClass: 'snack-error'
          });
          }
        });
      }
  }
  closeAlert() {
    this.showAlert = false;
  }
  noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string; // Ensure value is a string
      console.log('hooooooooooooo')
      if ( value!=null && typeof value === 'string' && !value.includes(' ')) {
        return null; // Validation passed
      }
      return { 'Spaces': true }; // Validation failed
  }
}
phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string; // Ensure value is a string
    if (value!='' && /^\d+$/.test(value)) {
      // Check if the length is 10
      if (value.length === 10) {
        return null; // Validation passed
      } else {
        return { 'phoneNumber': true }; // Validation failed due to length
      }
    
  
  
  }
  else{
    return { 'phoneNumber': false }
  };
}
}
ispincodeinvalid() {
  if (this.registerForm && this.registerForm.value && this.registerForm.value.pincode!='') {
    return (this.registerForm.value.pincode !='' && !/^[a-zA-Z0-9\s]+$/.test(this.registerForm.value.pincode))
  }
  return false; // Default to false if registerForm or pincode value is null or undefined
}
characterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string; // Ensure value is a string
    if (value !== "" && !/^[a-zA-Z\s]+$/.test(value)) {
      return { 'Nocharacter': true }; // Validation failed
  }
    return null; // Validation passed
  };
}
}
