import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Stomp from 'stompjs';
import { catchError, map } from 'rxjs/operators';import * as SockJS from 'sockjs-client';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient, public matSnackBar: MatSnackBar, private userService: UsersService) {  }
  adminId;
  userType;
  chatBoxCount: any;
  showConversation = false;
  ws: any;
  disabled: boolean;  refreshData: BehaviorSubject<any> = new BehaviorSubject('Untouched');
  userDetails:BehaviorSubject<any> = new BehaviorSubject({});
  UpdtaedUserDetails=this.userDetails.asObservable();
  
  UpdateUserDetails(updatedDetails){

    this.userDetails.next(updatedDetails)
  }
  intialize() {
    this.adminId = this.userService.getUserDetails().adminId;
    this.userType = this.userService.getUserDetails().userType;
    this.connect();
  }
  connect() {
    const url = environment.LOGIN_API_ENDPOINT + 'greeting';
    const socket = new SockJS(url);
    this.ws = Stomp.over(socket);    const that = this;
    this.ws.connect({}, function (frame) {
      that.ws.subscribe('/errors', function (message) {
      });
      that.ws.subscribe('/topic/refresh/' + that.userService.getOrgId() + '/' + that.userService.getUserId(), function (message) {  
        that.refreshData.next(JSON.parse(message.body));
      });      that.disabled = true;
    }, function (error) {    });  }
  connect_callback = function() {  };
  error_callback = function(error) {    alert(error.headers.message);
  };
  close() {
    if (this.ws.connected) {
      this.ws.disconnect({}, function (frame) {
      });
    }  }  disconnect() {
    if (this.ws !== null) {
      this.ws.ws.close();
    }
    this.setConnected(false);
  }  sendName() {
    const data = JSON.stringify({      'orgId': this.userService.getOrgId(),
      'userLoginId': this.userService.getUserId(),
      'adminId': this.adminId,
      'userType': 'admin'
    });
    const msgReq = '/app/message/' + this.adminId;
    this.ws.send(msgReq, {}, data);
  }  setConnected(connected) {
    this.disabled = connected;
    this.showConversation = connected;  }
  getAllActions() {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'category_alerts_fileaction?userType=' + this.userService.getUserDetails().userType + '&adminId=' + this.userService.getUserDetails().adminId + '&userId=' + this.userService.getUserId() + '&orgId=' + this.userService.getOrgId() + '&index=' + 0, '')
      .pipe(map((response: any) => response));
  }
  getAllNotifications(): Observable<any> {
    const fd = new FormData();
    fd.append('orgId', this.userService.getOrgId());
    fd.append('userType', this.userType);
    fd.append('adminId', this.adminId);
    fd.append('userLoginId', this.userService.getUserId());
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'fileuploadalert?index=' + 0, fd).pipe(map((response: any) => response));
  }
  changeCategoryStatus(catId, status) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'acceptDeclineCategory?button=' + status + '&categoryId=' + catId, '')
      .pipe(map((response: any) => response));
  }
  getUnreadMessagesCount() {
    return this.http.get(environment.LOGIN_API_ENDPOINT + 'chatBoxCount?orgId=' + this.userService.getOrgId() + '&userId=' + this.userService.getUserId())
      .pipe(map((response: any) => response));
  }
  changeMessageSeenStatus(receiverId, senderId) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'removeChat',
      {
        'senderId': senderId,
        'receiverId': receiverId,
        'orgId': this.userService.getOrgId()
      })
      .pipe(map((response: any) => response));
  }
  getChatBoxCount(chatBoxLength) {
    this.chatBoxCount = chatBoxLength;
  }
  changeNotificationsStatus(Ids) {
    return this.http.post(environment.LOGIN_API_ENDPOINT + 'alertSeenStatus?alertIds=' + Ids, '')
      .pipe(map((response: any) => response));
  }
  getRefreshData() {
    return this.refreshData.asObservable();
  }
  getOnGoingProcess() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_ongoing_processes_data?user_login_id=' +  this.userService.getUserId() + '&org_id=' +  this.userService.getOrgId())
      .pipe(map((response: any) => response));
  }
}
